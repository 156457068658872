import {
    Box, Flex, useDisclosure, Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
} from '@chakra-ui/react'
import ChatSidebar from './ChatSidebar'
import Header from '../Layout/Header'
import ChatMain from './ChatMain'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchChatHistory, fetchChatSessionHistory, loadUserDocs } from '../../utils/apiUtils';
import { ChatUnusableModal } from '../ChatUnusableModal';

const Chat = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigator = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [messages, setMessages] = useState([]);
    const [chatSessionId, setChatSessionId] = useState(uuidv4());
    const [chatHistory, setChatHistory] = useState([]);
    const [userDocs, setUserDocs] = useState([]);
    useEffect(() => {
        getAccessTokenSilently().then((token) => {
            loadUserDocs(token).then((docs) => {
                setUserDocs(docs)
            });
        });
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            getAccessTokenSilently().then((token) => {
                fetchChatHistory(token).then((data) => {
                    setChatHistory(data);
                });
            });
        }
    }, [isAuthenticated, getAccessTokenSilently]);  

    const onNewChatClick = () => {
        navigator('/chat');
    }

    const onClickChatLink = (chatSessionId) => {
        getAccessTokenSilently().then((token) => {
            fetchChatSessionHistory(chatSessionId, token).then((messages) => {
                setMessages(messages);
                setChatSessionId(chatSessionId);
            });
        });
    }

    return (
        <>
            <Flex w={"100%"}  mx="auto" h="100vh" position="relative" >
                <Box display={{ base: "none", lg: "block" }}>
                    <Box w="280px" position={"absolute"} top={0} bottom={0} left={0} >
                        <ChatSidebar
                            chatHistory={chatHistory}
                            onClickChatLink={onClickChatLink}
                            onNewChatClick={onNewChatClick}
                            onClose={onClose}
                        />
                    </Box>
                    <Box w={"280px"}></Box>
                </Box>
                <Box w={{ base: "100%", lg: "calc(100vw - 280px)" }} >
                    <Flex flexDir="column" w="100%" h="100vh">
                        <Box>
                            <Header isChat onChatOpen={onOpen} />
                        </Box>
                        <Box flex={1} px={{ base: '16px', xl: '0px' }}>
                            <Box w="100%" maxW="800px" mx="auto" >
                                <ChatMain messages={messages} setMessages={setMessages} chatSessionId={chatSessionId} />
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                size={{ base: "xs", sm: 'sm' }}
            >
                <DrawerOverlay />
                <DrawerContent w="100%">
                    <DrawerBody p={"0px"}>
                        <ChatSidebar
                            chatHistory={chatHistory}
                            onClickChatLink={onClickChatLink}
                            onNewChatClick={onNewChatClick}
                            onClose={onClose}
                        />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
            <ChatUnusableModal isUnusable={userDocs.length === 0} />
        </>
    )
}

export default Chat