import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import DocumentUploadPage from './containers/DocumentUpload';
import { extendTheme } from "@chakra-ui/react";
import '@fontsource/inter'
import { Route, Routes, Navigate } from 'react-router-dom';
import ChatPage from './containers/ChatPage';
import ViewDocumentsPage from './containers/ViewDocumentsPage';
import ViewPDFPage from './containers/ViewPDFPage';
import { AuthenticationGuard } from './components/AuthenticationGuard';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';
import useSubscriptionStatus from './utils/useSubscriptionStatus';
import { LoadingPage } from './containers/LoadingPage';

const theme = extendTheme({
  fonts: {
    body: `'Inter', sans-serif`,
  },
})
function App() {
  const { isLoading, isAuthenticated, user, loginWithRedirect } = useAuth0();
  const {isSubscribed, setIsSubscribed, isTrialUser, daysRemainingInTrial, isLoadingSubscriptionState, setIsLoadingSubscriptionState, isEmailVerified} = useSubscriptionStatus();

  const [searchParams] = useSearchParams();

  const returningFromSuccessfulCheckout = searchParams.get('subscriptionSuccess') === '1';

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // in this case the user is not authenticated, so we can assume they are not subscribed
      setIsSubscribed(false);
      setIsLoadingSubscriptionState(false);
    }
    // eslint-disable-next-line
  }, [user, isAuthenticated, isLoading]);


  useEffect(() => {
    if (isLoadingSubscriptionState) {
      // If you have logic that should run when isLoading is true, place it here.
      return; // Prevents the rest of the hook from running when isLoading.
    }

    if (isAuthenticated && user) {
      window.heap.identify(user.email);
    }
    // The dependencies array ensures this effect runs only when isLoading, isAuthenticated, or user.email changes.
  }, [isLoadingSubscriptionState, isAuthenticated, user]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoadingSubscriptionState || isLoading) {
    return <LoadingPage />
  }

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={isAuthenticated ? <Navigate to="/chat" state={{subscriptionSuccess: returningFromSuccessfulCheckout}} /> : <LoadingPage />} />
        {/* <Route path="/" element={isAuthenticated ? <Navigate to="/getting-started" state={{subscriptionSuccess: returningFromSuccessfulCheckout}} /> : (window.location.href = "https://specgpt.ai")} /> */}
        <Route exact path="/chat" element={<AuthenticationGuard component={ChatPage} isSubscribed={isSubscribed} isEmailVerified={isEmailVerified} isTrialUser={isTrialUser} daysRemainingInTrial={daysRemainingInTrial} />} />                
        <Route exact path="/source" element={<AuthenticationGuard component={ViewPDFPage} isSubscribed={isSubscribed} isEmailVerified={isEmailVerified} isTrialUser={isTrialUser} daysRemainingInTrial={daysRemainingInTrial} />} />
        <Route exact path="/getting-started" element={<AuthenticationGuard component={DocumentUploadPage} isSubscribed={isSubscribed} isEmailVerified={isEmailVerified} isTrialUser={isTrialUser} daysRemainingInTrial={daysRemainingInTrial} />} />
        <Route exact path="/uploaded-files" element={<AuthenticationGuard component={ViewDocumentsPage} isSubscribed={isSubscribed} isEmailVerified={isEmailVerified} isTrialUser={isTrialUser} daysRemainingInTrial={daysRemainingInTrial} />} />
        {/* <Route exact path="/admin-dashboard" element={<AdminDashboardPage />} /> */}
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;