import {
    Box, Flex, List, ListItem, Text, useDisclosure, Drawer,
    Button,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Image,
} from '@chakra-ui/react'
import React from 'react'
import { ChatbotIcon, CloseIcon, Logo, MenuIcon } from '../../../assets/icons'
import { Link } from 'react-router-dom'
import { createStripePortalSession } from "../../../utils/apiUtils";
import useSubscriptionStatus from '../../../utils/useSubscriptionStatus';
import { useAuth0 } from '@auth0/auth0-react';

const Header = ({ isChat,onChatOpen }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const { isTrialUser, daysRemainingInTrial } = useSubscriptionStatus();
    const {getAccessTokenSilently, user, logout} = useAuth0();

    const createPortalSession = async () => {
        const token = await getAccessTokenSilently();
        return createStripePortalSession(token);
    }
    return (
        <>
            <Box borderBottom="1px" borderColor="#EDEDED" px={{ base: '16px', xl: !isChat ? '0px':"24px" }}>
                <Box maxW="1280px" mx='auto'>
                    <Flex align='center' justifyContent='space-between' py={5}  >
                        <Box display={{ base: "block", lg: "none" }} onClick={onChatOpen} >
                            <ChatbotIcon />
                        </Box>
                        <Box>
                            <Link to="/">
                                <Logo />
                            </Link>
                        </Box>
                        <List gap="45px" display={isChat ? { base: "none", xl: "flex" } : { base: "none", lg: "flex" }}>
                            <ListItem cursor="pointer" color="#0E2332">
                                <Link to={"/chat"}>
                                    Chat
                                </Link>
                            </ListItem>
                            <ListItem cursor="pointer" color="#0E2332">
                                <Link to="/">
                                    Add Documents
                                </Link>
                            </ListItem>
                            <ListItem cursor="pointer" color="#0E2332">
                                <Link to='/uploaded-files'>
                                    View Documents
                                </Link>
                            </ListItem>
                            <ListItem cursor="pointer" color="#0E2332">
                                <Link onClick={() => createPortalSession().then((url) => {
                                    if (url) {
                                        window.open(url, '_blank');
                                    }
                                })}>
                                    {isTrialUser ? `${daysRemainingInTrial} day${daysRemainingInTrial > 1 ? "s" : ""} left in trial: Subscribe` : "Manage Subscription"}
                                </Link>
                            </ListItem>
                        </List>
                        <Box cursor={"pointer"} display={{ base: "flex", lg: "none" }} onClick={onOpen} ref={btnRef}>
                            <MenuIcon />
                        </Box>
                        <Flex align="center" gap={2} display={{ base: "none", lg: "flex" }}>
                            <Button colorScheme='yellow' mr={2} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                                Logout
                            </Button>
                            <Flex align="center" justifyContent="center" bgColor="#000000" w="40px" h="40px" borderRadius="8px">
                                <Image src={user.picture} borderRadius="8px"></Image>
                            </Flex>
                            <Text>
                                {user.email}
                            </Text>
                        </Flex>
                    </Flex>
                </Box >
            </Box>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size={{ base: "xs", sm: 'sm' }}
            >
                <DrawerOverlay />
                <DrawerContent w="100%">
                    <DrawerHeader>
                        <Box onClick={onClose}>
                            <CloseIcon />
                        </Box>
                    </DrawerHeader>
                    <DrawerBody>
                        <Flex justifyContent={"center"} mt={5} >
                            <List spacing="45px">
                                <ListItem cursor="pointer" textAlign={"center"} color="#0E2332">
                                    <Link to={"/chat"}>
                                        Chat
                                    </Link>
                                </ListItem>
                                <ListItem cursor="pointer" textAlign={"center"} color="#0E2332">
                                    <Link to={"/"}>
                                        Add Documents
                                    </Link>
                                </ListItem>
                                <ListItem cursor="pointer" textAlign={"center"} color="#0E2332">
                                    <Link to={"/uploaded-files"}>
                                        View Documents
                                    </Link>
                                </ListItem>
                                <ListItem cursor="pointer" textAlign={"center"} color="#0E2332">
                                    <Link onClick={() => createPortalSession().then((url) => {
                                        if (url) {
                                            window.open(url, '_blank');
                                        }
                                    })}>
                                        {isTrialUser ? `${daysRemainingInTrial} day${daysRemainingInTrial > 1 ? "s" : ""} left in trial: Subscribe` : "Manage Subscription"}
                                    </Link>
                                </ListItem>
                                <ListItem cursor="pointer" textAlign={"center"} color="#0E2332">
                                    <Link onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                                        Logout
                                    </Link>
                                </ListItem>
                            </List>
                        </Flex>
                    </DrawerBody>
                    <DrawerFooter>
                        <Flex w="100%" align="center" gap={2} >
                            <Flex align="center" justifyContent="center" bgColor="#000000" w="40px" h="40px" borderRadius="8px">
                                <Image src={user.picture} borderRadius="8px"></Image>
                            </Flex>
                            <Text>
                                {user.email}
                            </Text>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default Header