const getEnvironmentData = () => {
  const inProdEnvironment = process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'prod';
  const inQaEnvironment = process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'qa';
  return {
    inProdEnvironment,
    inQaEnvironment
  }
}

// Implement the functions with type annotations
const getBaseUrl = () => {
  const { inProdEnvironment, inQaEnvironment } = getEnvironmentData();
  const dev = 'http://localhost:8001';
  const prod = 'https://api.specgpt.ai';
  const qa = 'https://api-qa.specgpt.ai';
  return inProdEnvironment ? prod : inQaEnvironment ? qa : dev;
};

const getAuth0Audience = () => {
  const { inProdEnvironment, inQaEnvironment } = getEnvironmentData();
  const dev = 'https://dev-api.specgpt.ai';
  const prod = 'https://api.specgpt.ai';
  return inProdEnvironment ? prod : dev;
};

const getAuth0Domain = () => {
  const { inProdEnvironment, inQaEnvironment } = getEnvironmentData();
  const dev = 'specgpt-dev.us.auth0.com';
  const prod = 'thelink.us.auth0.com';
  return inProdEnvironment ? prod : dev;
};

const getAuth0ClientId = () => {
  const { inProdEnvironment, inQaEnvironment } = getEnvironmentData();
  const dev = 'KAYNuwQALbq6Sb72WcuFJOjKoP0hExQA';
  const prod = 'sr2yGwVmtmsufUNJsj8ShUE8PeTNZqn1';
  return inProdEnvironment ? prod : dev;
};

const getStripeDashboardUrl = () => {
  const { inProdEnvironment, inQaEnvironment } = getEnvironmentData();
  const dev = "https://billing.stripe.com/p/login/test_aEU2brggr8AVao89AA";
  const prod = "https://billing.stripe.com/p/login/test_aEU2brggr8AVao89AA";
  return inProdEnvironment ? prod : dev;
};

// Export the configuration as a typed object
export const config = {
  BASE_URL: getBaseUrl(),
  AUTH0_AUDIENCE: getAuth0Audience(),
  AUTH0_DOMAIN: getAuth0Domain(),
  AUTH0_CLIENT_ID: getAuth0ClientId(),
  STRIPE_DASHBOARD_URL: getStripeDashboardUrl(),
};

// export const BASE_URL = getBaseUrl();
// export const AUTH0_AUDIENCE = getAuth0Audience();
// export const AUTH0_DOMAIN = getAuth0Domain();
// export const AUTH0_CLIENT_ID = getAuth0ClientId();
