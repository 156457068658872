import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, Button, Text, CircularProgress, Flex } from '@chakra-ui/react';
import { createStripeCheckoutSession, resendVerificationEmail } from '../utils/apiUtils';
import { useAuth0 } from '@auth0/auth0-react';

export const SubscribeModal = ({ isSubscribed, isEmailVerified }) => {
    const {getAccessTokenSilently, logout} = useAuth0();

    const createCheckoutSession = async () => {
        const token = await getAccessTokenSilently();
        return createStripeCheckoutSession(token);
    }
    return (
        <>
            {!isSubscribed && isEmailVerified && <Modal closeOnOverlayClick={false} isCentered isOpen={true} onClose={()=>{}}>
                <ModalOverlay/>
                <ModalContent>
                    <>
                        <ModalHeader>Subscribe to Continue</ModalHeader>
                        <ModalBody>
                            <Text>
                                Subscribe to SpecGPT for $50/mo to continue.
                            </Text>
                        </ModalBody>
                        <ModalFooter justifyContent={'space-between'}>
                            <Button variant={'outline'} onClick={() => {logout()}}>Cancel and Logout</Button>
                            <Button colorScheme='yellow' onClick={() => {createCheckoutSession().then((url) => {
                                if (url) {
                                    window.location.href = url;
                                }
                            })}}>
                                Subscribe
                            </Button>
                        </ModalFooter>
                    </>
                </ModalContent>
            </Modal>}
            {!isEmailVerified && <Modal closeOnOverlayClick={false} isCentered isOpen={true} onClose={()=>{}}>
                <ModalOverlay/>
                <ModalContent>
                    <>
                        <ModalHeader>Verify Email to Continue</ModalHeader>
                        <ModalBody>
                            <Text>
                                Check your email for a verification link. Once your email is verified, refresh this page to use SpecGPT.
                            </Text>
                        </ModalBody>
                        <ModalFooter justifyContent={'space-between'}>
                            <Button variant={'outline'} onClick={() => {logout()}}>Logout</Button>
                            <Button colorScheme='yellow' onClick={() => {getAccessTokenSilently().then((token) => {resendVerificationEmail(token)})}}>
                                Resend Email
                            </Button>
                        </ModalFooter>
                    </>
                </ModalContent>
            </Modal>}
        </>
    )
};

