import React from 'react'

import Layout from '../../components/Layout'
import DocumentUpload from '../../components/DocumentUpload'
const DocumentUploadPage = () => {
    return (
        <Layout>
            <DocumentUpload />
        </Layout>
    )
}

export default DocumentUploadPage