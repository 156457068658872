import { config } from "./config";
import { MESSAGE_ROLE_TYPE } from "./enums";

const fetchPdf = async (fileId, authToken) => {
    try {
        const response = await fetch(`${config.BASE_URL}/api/signed-url?id=${fileId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + authToken,
            },
        });
        const data = await response.json();
        if (data.success) {
            console.log('signed url', data.url);
            return data.url
        }
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
    return null;
}

const loadQuestionRating = async (questionId, token) => {
    const url = `${config.BASE_URL}/api/rating?question_id=${questionId}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
        },
        });
        const data = await response.json();
        if (data.success) {
            return data.rating;
        }
    } catch (error) {
        console.log('error: ', error);
        return 0;
    }
    return 0;
}

const rateQuestion = async (rating, questionid, token) => {
    const url = `${config.BASE_URL}/api/rating`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                question_id: questionid,
                rating: rating,
            })
        });
        const data = await response.json();
        if (data.success) {
            return data.rating;
        }
    } catch (error) {
        console.log('error: ', error);
        return 0;
    }
    return 0;
}

const fetchChatHistory = async (token) => {
    const url = `${config.BASE_URL}/api/chat-history`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        if (data.success) {
            return [...data.chat_history];
        }
    } catch (error) {
        console.log('Error: ', error);
        return [];
    }   
    return [];
}


const fetchChatSessionHistory = async (chatSessionID, token) => {
    const url = `${config.BASE_URL}/api/chat-session-history?chat_session_id=${chatSessionID}`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        if (data.success) {
            return data.chat_history
        }
    } catch (error) {
        console.log('Error: ', error);
        return [];
    }
    return [];
}

const ERROR_MESSAGE = "I’m unable to answer that question, can you please restate? Try to make it more specific or narrower if possible."

const fetchPromptAnswer = async (prompt, k, chatSessionId, token) => {
    const url = `${config.BASE_URL}/api/doc-chat-stream`;            
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
        },
            body: JSON.stringify({
                'prompt': prompt,
                'k': k,
                'chat_session_id': chatSessionId,
            }),
        });
        if (!response.body) return {session_id: chatSessionId, questionid: '', role: MESSAGE_ROLE_TYPE.ERROR, message: ERROR_MESSAGE};
        if (response.status === 400) {
            // return 400 error
            const data = await response.json();
            const message = data.message;
            return {session_id: chatSessionId, questionid: '', role: MESSAGE_ROLE_TYPE.ERROR, message: message};
            }
        if (response.status === 500) {
            return {session_id: chatSessionId, questionid: '', role: MESSAGE_ROLE_TYPE.ERROR, message: ERROR_MESSAGE};
        }
        const data = await response.json();
        const answer = data.answer;
        const questionid = data.questionid;
        const sources = data.sources;
        console.log(sources);
        return {session_id: chatSessionId, questionid: questionid, role: MESSAGE_ROLE_TYPE.ASSISTANT, message: answer, sources: sources};

    } catch (error) {
        console.log('Error: ', error);
        return {session_id: chatSessionId, questionid: '', role: MESSAGE_ROLE_TYPE.ERROR, message: ERROR_MESSAGE};
    }
}

const countUserDocs = async (token) => {
    const url = `${config.BASE_URL}/api/doc-count`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        if (data.success) {
            return data.count;
        }
    } catch (error) {
        console.log('Error: ', error);
        return null;
    }
    return null;
}

const purgeFiles = async (token) => {
    const url = `${config.BASE_URL}/api/purge-files`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        if (data.success) {
            return true;
        }
    } catch (error) {
        console.log('Error: ', error);
        return false;
    }
    return false;
}

const ProcessingStatus = {
    IN_QUEUE: 'IN_QUEUE',
    PROCESSING: 'PROCESSING',
    PROCESSED: 'PROCESSED',
    FAILED: 'FAILED',
}

const loadUserDocs = async (token) => {
    const url =`${config.BASE_URL}/api/docs`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        if (data.success) {
            return data.docs;
        }            
    } catch (error) {
        console.log('error: ', error);
        return [];
    }
    return [];
}

const createStripeCheckoutSession = async (token) => {
    const url = `${config.BASE_URL}/create-checkout-session`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json()
        return data.url;
    } catch (error) {
        console.log('Error: ', error);
        return null;
    }
    return null;
}

const createStripePortalSession = async (token) => {
    const url = `${config.BASE_URL}/create-portal-session`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json()
        return data.url;
    } catch (error) {
        console.log('Error: ', error);
        return null;
    }
    return null;
}

const hasActiveSubscription = async (token) => {
    const url = `${config.BASE_URL}/get-subscription-status`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json()
        return data;
    } catch (error) {
        console.log('Error: ', error);
        return null;
    }
    return null;
}

const resendVerificationEmail = async (token) => {
    const url = `${config.BASE_URL}/resend-verification-email`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log('Error: ', error);
        return false;
    }
    return false;
}

export {
    fetchPdf,
    loadQuestionRating,
    rateQuestion,
    fetchChatHistory,
    fetchChatSessionHistory,
    fetchPromptAnswer,
    countUserDocs,
    purgeFiles,
    ProcessingStatus,
    loadUserDocs,
    createStripeCheckoutSession,
    createStripePortalSession,
    hasActiveSubscription,
    resendVerificationEmail
};