import React, { ComponentType, ReactElement } from "react";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingPage } from "../containers/LoadingPage";
import { SubscribeModal } from "./SubscribeModal";
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

export const AuthenticationGuard = ({ component, isSubscribed, isEmailVerified }) => {
  // const location = useLocation();
  // const toast = useToast();

  // const returningFromSuccessfulCheckout = location.state?.subscriptionSuccess;
  // console.log("returningFromSuccessfulCheckout", returningFromSuccessfulCheckout);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingPage />,
  });

  return (<>
    {/* TODO SGPT-103: This is causing strange UI bugs for some reason with a number floating above the navbar and duplicate toasts being shown */}
    {/* {!!returningFromSuccessfulCheckout && toast({
          title: 'Subscription successful',
          description: "You're all set. Your 14 day trial has started.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        }
      )
    } */}
    <SubscribeModal isSubscribed={isSubscribed} isEmailVerified={isEmailVerified} />
    <Component />
  </>
  );
};

